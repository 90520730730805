import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { onDesktop } from "../../shared/layout/Breakpoints";
import { ProjectPage } from "../../components/ProjectPage";
import { Collage, CollageType } from "../../components/collages/Collage";
import TextContent from "../../components/shared/TextContent";
import Banner from "../../components/shared/Banner";
import ZoomableImage from "../../components/shared/ZoomableImage";

const Triptych = styled.div`
  display: flex;
  flex-direction: row;

  margin: 0 -1em;

  ${onDesktop`
    margin: 0;
  `}
`;

const TriptychImage = styled(ZoomableImage)`
  flex: 1 0 auto;

  &:first-child,
  &:last-child {
    flex-shrink: 1;
  }

  &:nth-child(2) {
    flex-basis: 25%;
  }
`;

export const TriptychSideFile = graphql`
  fragment TriptychSideFile on File {
    childImageSharp {
      fluid(maxWidth: 1357) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
export const TriptychMainFile = graphql`
  fragment TriptychMainFile on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
type TryptichFile = {
  childImageSharp: { fluid: FluidObject };
};

const TriptychPag = () => {
  const images = useStaticQuery<{
    part1: TryptichFile;
    part2: TryptichFile;
    part3: TryptichFile;
  }>(graphql`
    query {
      part1: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part1_A3.png" }
      ) {
        ...TriptychSideFile
      }
      part2: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part2_A3.png" }
      ) {
        ...TriptychMainFile
      }
      part3: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part3_A3.png" }
      ) {
        ...TriptychSideFile
      }
    }
  `);

  return (
    <ProjectPage name="The Internet Triptych">
      <Banner>
        <Triptych>
          <TriptychImage fluid={images.part1.childImageSharp.fluid} />
          <TriptychImage fluid={images.part2.childImageSharp.fluid} />
          <TriptychImage fluid={images.part3.childImageSharp.fluid} />
        </Triptych>
      </Banner>
      <TextContent>
        <h1>The Internet Triptych</h1>
        <p>
          In this three-part drawing, Laura finds inspiration form H. Bosch and
          his painting The Garden of Earthly Delights (1490-1510) and uses it to
          explore the relationship between people and internet in an analogy
          with Bosch's work: Paradise is the past, earth is the infancy of the
          internet, and hell is the dark future of the internet. Laura explores
          the positive and negative impacts in the way we communicate as a
          global society and reflects on what could happen if we lost the
          internet as we know it today. The first panel represents the era
          before the internet existed, in the 1900s, the way peoples of
          different cultures would encounter each other through tourism and
          second-hand information. The middle panel represents the moment when
          the internet became accessible to everyone. The internet was a
          mystery, we were all naked unbeknownst of the consequences of what we
          were doing, finding closure, finding the other, and formulating
          questions. There is a duality of opposing forces. We find glimpses of
          clarity, while we find endless sources of unreliable information. The
          internet is represented as a constantly changing entity, who is
          neither good or bad, but whose consequences reflects on who uses it.
        </p>
        <p>
          The last panel represents the hell, the dark future of the internet.
          The internet is now impaired, and systematically used for control of
          the masses, at the service of regulatory bodies, where only those who
          are privileged can enjoy its benefits. The internet is now like a
          spider web, sensitive to every single movement of each user. We come
          back to a filtered reality, where you are offered only what you should
          know, and the other is removed. The powerful can hide behind
          censorship. They will not allow you to know about oppression and war,
          and such images will be hidden behind firewalls and filters of the new
          social media. Every user now lives in their personal bubble, without
          needing to connect.{" "}
        </p>
        <p>
          Are there alternatives? Can we work to obtain a more humane internet?
          With this tryptic, Laura reflects on the good aspects of the internet,
          to give them importance, as they need to be protected. We are becoming
          a global society, becoming more tightly dependable on each other, thus
          with a need to openly understand each other, a task that the internet
          has the potential to mediate.
        </p>
      </TextContent>
      <Collage type={CollageType.InternetTriptych} />
    </ProjectPage>
  );
};

export default TriptychPag;
